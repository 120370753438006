export const GET_ITEM = "GET_ITEM";

export const HAMBURGER_MENU_TOGGLE = "HAMBURGER_MENU_TOGGLE";

export const SAVE_OFFER_TOP = "SAVE_OFFER_TOP";
export const SAVE_SHORT_ABOUT_TOP = "SAVE_SHORT_ABOUT_TOP";
export const SAVE_HOW_WE_BUILD_TOP = "SAVE_HOW_WE_BUILD_TOP";
export const SHOW_MODAL_SEND_EMAIL = "SHOW_MODAL_SEND_EMAIL";
export const SAVE_GALLERY_TOP = "SAVE_GALLERY_TOP";
export const SHOW_GALLERY_PIC = "SHOW_GALLERY_PIC";
export const SELECTED_PICTURE_URL = "SELECTED_PICTURE_URL";
